import React from "react";
import { Col, Form } from "react-bootstrap";
import { Element } from "react-scroll";
import { isInternal } from "../../helpers";
import { getClasses } from "../../helpers/getClasses";
import InfoIcon from "../order/InfoIcon";
import ShowMoreText from "react-show-more-text";
import { useProductInput } from "../../hooks";
export const BlockCheckbox = (props) => {
  const {
    className,
    modal,
    discount,
    discounted_class,
    controlId,
    col = true,
    short_labels,
    ...options
  } = props;

  const { fieldProps, fieldState, productFields } = useProductInput({
    ...options,
    type: "checkbox",
  });
  const { label, price, description } = productFields;
  const { value, type, name } = fieldProps;

  const { invalid, isDirty } = fieldState;
  return (
    <VariantCol col={col}>
      <Form.Group
        controlId={type === "radio" ? controlId : name}
        as={Element}
        className="variant-group"
      >
        <Form.Check
          type={type}
          className={getClasses(["variant", value && "checked", className])}
          custom
        >
          <Form.Check.Input
            {...fieldProps}
            checked={Boolean(value)}
            isInvalid={invalid && isDirty}
          />
          <Form.Check.Label className={discounted_class}>
            {label}
            {description && (
              <Form.Text className="flex-grow-1 mb-3 ">
                <ShowMoreText
                  lines={isInternal() || short_labels ? 1 : 7}
                  keepNewLines
                >
                  {description}
                </ShowMoreText>
              </Form.Text>
            )}
            <Form.Text
              className={`${getClasses([
                discount && "discounted",
              ])} price-wrapper `}
            >
              <div>
                <span className="price">{price}</span>{" "}
                {discount && <span className="discount">{discount}</span>}
              </div>
              {!isInternal() && modal && <InfoIcon modal={modal} />}
            </Form.Text>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>
    </VariantCol>
  );
};

const VariantCol = ({ col, children }) => {
  if (col)
    return (
      <Col sm={6} md={4} className="mb-5">
        {children}
      </Col>
    );
  return children;
};
