import React from "react";
import { Form } from "react-bootstrap";
import { useWatch } from "react-hook-form";
import { Element } from "react-scroll";
import { isInternal } from "../../helpers";
import { getClasses } from "../../helpers/getClasses";
import { useProductInput } from "../../hooks";
import InfoIcon from "../order/InfoIcon";

export const InlineCheckbox = (props) => {
  const {
    className,
    modal,
    controlId,
    discount,
    discounted_class,
    isNew,
    labelClassName,
    onClick,
    ...options
  } = props;
  const { fieldProps, fieldState, productFields } = useProductInput({
    type: "checkbox",
    ...options,
  });

  const radioValue = useWatch({ name: fieldProps.name });
  const { value, type, name } = fieldProps;
  const { label, price, description } = productFields;
  const { error, validated, invalid } = fieldState;
  const checked = type === "radio" ? value === radioValue : Boolean(value);
  return (
    <Form.Group
      controlId={
        type === "radio" ? controlId || `${name}-${options.sku}` : name
      }
      as={Element}
      className="mb-4"
      onClick={onClick}
    >
      <Form.Check
        type={type}
        className={getClasses(["standard", !!checked && "checked", className])}
        custom
      >
        <Form.Check.Input
          {...fieldProps}
          checked={checked}
          isInvalid={invalid}
        />
        <Form.Check.Label
          className={getClasses([discounted_class, labelClassName])}
        >
          {isNew && <span className="new-product-label">NEW! </span>}
          <span>{label}</span>{" "}
          {price && (
            <>
              <span className="price">{price}</span>{" "}
              {discount && <span className="discount">{discount}</span>}
            </>
          )}
        </Form.Check.Label>
        {!isInternal() && modal && (
          <InfoIcon modal={modal} tooltip={description} />
        )}
        <Form.Control.Feedback type="invalid">
          {error && <div>{error.message}</div>}
        </Form.Control.Feedback>
        {(!validated || !error) && description && (
          <Form.Text
            className="font-weight-bold"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </Form.Check>
    </Form.Group>
  );
};
