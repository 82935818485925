import { useEffect, useMemo } from "react";
import { useProducts } from "../adapters/uplist";
import { useInSqftRange } from "./useInSqftRange";
import { useFormContext } from "react-hook-form";
import usePrevious from "./usePrevious";

const useGetSkuBySqft = (skus) => {
  const { data: products } = useProducts();
  const { getValues, setValue, resetField } = useFormContext();
  const inSqftRange = useInSqftRange();
  const sku = useMemo(
    () =>
      skus.find((sku) => {
        const { sqft_range } = products[sku];
        return inSqftRange(sqft_range);
      }),
    [inSqftRange, skus, products]
  );
  const oldSku = usePrevious(sku);
  useEffect(() => {
    if (oldSku && sku && oldSku !== sku) {
      const value = getValues(oldSku);
      if (value) {
        resetField(oldSku);
        setValue(sku, value);
      }
    }
  }, [sku, oldSku, getValues, setValue, resetField]);
  return sku;
};

export default useGetSkuBySqft;
