export const SLIDESHOW_SAMPLES = [
  "https://vimeo.com/596800966",
  "https://vimeo.com/620577034",
  "https://vimeo.com/619326456",
];

export const STANDARD_VIDEO_SAMPLES = [
  "https://vimeo.com/596700330",
  "https://vimeo.com/542008606",
  "https://vimeo.com/558657886",
];

export const PREMIUM_VIDEO_SAMPLES = [
  "https://vimeo.com/827394178",
  "https://vimeo.com/841353576",
];
