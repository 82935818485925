import One from "./1.jpg";
import Two from "./2.jpg";
import Three from "./3.jpg";
import Four from "./4.jpg";
import Five from "./5.jpg";
import Six from "./6.jpg";
import Seven from "./7.jpg";
import Eight from "./8.jpg";
import Nine from "./9.jpg";
const Aerial = [One, Two, Three, Four, Five, Six, Seven, Eight, Nine];

export const AERIAL_VIDEO_SAMPLES = [
  "https://vimeo.com/279102052",
  "https://vimeo.com/338306071",
  "https://vimeo.com/606752801",
];
export default Aerial;
