import { rangeString } from "./rangeString";

export const SIGN_UP_PATH_LIST = ["/signup", "/account-info", "/payment-info"];
export const WELCOME_PATH_LIST = ["/", "/no-account"];
export const HOME_PATH_LIST = [
  "/",
  "/no-account",
  "/login",
  "/forgot-password",
];

export const FORM_PATH_LIST = [
  "/new-order",
  "/bundles",
  "/photos",
  "/stock-photos",
  "/floorplans",
  "/matterport",
  "/video",
  "/aerial",
  "/sign",
  "/print",
  "/submit",
];
export const FORM_NAV_LIST = [
  "/new-order",
  "/bundles",
  "/photos",
  "/floorplans",
  "/matterport",
  "/video",
  "/aerial",
  "/sign",
  "/print",
  "/submit",
];
export const FORM_NAV_INDEX = {
  "/new-order": 0,
  "/bundles": 1,
  "/photos": 2,
  "/stock-photos": 2,
  "/floorplans": 3,
  "/matterport": 4,
  "/video": 5,
  "/aerial": 6,
  "/sign": 7,
  "/print": 8,
  "/submit": 9,
};
export const LABEL_LIST = [
  "New Order",
  "Bundles",
  "Photos",
  "Floor Plans",
  "Matterport",
  "Video",
  "Aerial",
  "Sign",
  "Print",
  "Submit",
];
export const DIRECTION_ARRAY = ["N", "S", "E", "W", "NE", "NW", "SE", "SW"];

export const SHORT_SUFFIX_NAMES = [
  "Ave",
  "Blvd",
  "Cir",
  "Cl",
  "Cres",
  "Crt",
  "Dr",
  "Gate",
  "Gdns",
  "Hts",
  "Hwy",
  "Ln",
  "Pkwy",
  "Pl",
  "Rd",
  "Ridge",
  "St",
  "Terr",
  "Way",
];

const FULL_SUFFIX_NAMES = [
  "Avenue",
  "Boulevard",
  "Close",
  "Crescent",
  "Court",
  "Drive",
  "Gardens",
  "Heights",
  "Highway",
  "Lane",
  "Parkway",
  "Place",
  "Road",
  "Street",
  "Terrace",
];

export const STREET_SUFFIXES = [...SHORT_SUFFIX_NAMES, ...FULL_SUFFIX_NAMES];

export const STREET_SUFFIX_CONVERSION = {
  Avenue: "Ave",
  Boulevard: "Blvd",
  Close: "Cl",
  Crescent: "Cres",
  Court: "Crt",
  Drive: "Dr",
  Gardens: "Gdns",
  Heights: "Hts",
  Highway: "Hwy",
  Lane: "Ln",
  Parkway: "Pkwy",
  Place: "Pl",
  Road: "Rd",
  Street: "St",
  Terrace: "Terr",
};

const PHOTO_APPT_SKUS = ["1010"];
const FLOORPLAN_APPT_SKUS = ["2001", "2003"];

export const TEXTURED_FP_SKUS = ["2019", "2018"];
export const GARDEN_FP_SKUS = ["2021", "2020"];
export const FURNISHED_FP_SKUS = ["2017"];
export const PREMIUM_FP_ADDON_SKUS = [
  ...TEXTURED_FP_SKUS,
  ...GARDEN_FP_SKUS,
  ...FURNISHED_FP_SKUS,
];

export const BRONZE_BUNDLE_SKUS = rangeString(7000, 7005);
export const SILVER_BUNDLE_SKUS = rangeString(7006, 7011);
export const GOLD_BUNDLE_SKUS = rangeString(7012, 7017);
export const PLATINUM_BUNDLE_SKUS = rangeString(7018, 7023);
export const ALL_BUNDLE_SKUS = [
  ...BRONZE_BUNDLE_SKUS,
  ...SILVER_BUNDLE_SKUS,
  ...GOLD_BUNDLE_SKUS,
  ...PLATINUM_BUNDLE_SKUS,
];
export const MATTERPORT_APPT_SKUS = rangeString(5001, 5006);
export const STANDARD_VIDEO_SKUS = rangeString(6000, 6005);

export const PREMIUM_VIDEO_SKUS = rangeString(6006, 6011);

export const VIDEO_APPT_SKUS = [...STANDARD_VIDEO_SKUS, ...PREMIUM_VIDEO_SKUS];

export const AERIAL_APPT_SKUS = ["4001", "4006", "4007", "4301"];

export const BROCHURE_PRINT_SKUS = [
  "3201",
  "3202",
  "3203",
  "3206",
  "3207",
  "3209",
  "3210",
  "3212",
];

export const RACK_CARD_PRINT_SKUS = rangeString(3101, 3104);

export const NON_DISCOUNT_APPT_SKUS = [
  "1306",
  "1005",
  "1390",
  "1004",
  "1008",
  "1007",
  "1006",
];
export const APPOINTMENT_SKUS = [
  ...PHOTO_APPT_SKUS,
  ...FLOORPLAN_APPT_SKUS,
  ...MATTERPORT_APPT_SKUS,
  ...VIDEO_APPT_SKUS,
  ...AERIAL_APPT_SKUS,
];

export const NON_MATTERPORT_APPT_SKUS = APPOINTMENT_SKUS.filter(
  (sku) => !MATTERPORT_APPT_SKUS.includes(sku)
);

export const PRINT_MINIMUMS_BY_SKU = {
  3101: 6,
  3103: 9,
  3102: 4,
  3104: 6,
  3401: 21,
};

export const FLOORPLAN_COLOR_SKUS = ["2012", "2013", "2014", "2015", "2016"];

export const VIRTUAL_STAGING_STYLES = {
  Coastal: {
    single: "1320",
    bundle: "1321",
  },
  Contemporary: {
    single: "1322",
    bundle: "1323",
  },
  Farmhouse: {
    single: "1324",
    bundle: "1325",
  },
  Industrial: {
    single: "1326",
    bundle: "1327",
  },
  Modern: {
    single: "1328",
    bundle: "1329",
  },
  Scandinavian: {
    single: "1330",
    bundle: "1331",
  },
  Traditional: {
    single: "1332",
    bundle: "1333",
  },
};

export const DISCOUNT_SKUS = [
  { product_skus: [["4001"], PREMIUM_VIDEO_SKUS], discount_sku: "4002" },
];

export const RADIO_SKUS = [
  ["1004", "1008"],
  ["1006", "1007"],
  ["1318", "1317"],
  ALL_BUNDLE_SKUS,
  Object.keys(VIRTUAL_STAGING_STYLES),
  FLOORPLAN_COLOR_SKUS,
  ["premium_fp_addons", "standard_fp_addons"],
  TEXTURED_FP_SKUS,
  GARDEN_FP_SKUS,
  [...VIDEO_APPT_SKUS, "1312"],
  ["6101", "6100"],
  AERIAL_APPT_SKUS,
  ["3001", "3006", "3300", "3003", "3213"],
  ["3004", "3005"],
];
