import One from "./1.jpg";
import Two from "./2.jpg";
import Three from "./3.jpg";
import Four from "./4.jpg";
import Five from "./5.jpg";
import Six from "./6.jpg";
import Seven from "./7.jpg";
import Eight from "./8.jpg";
const Twilight = [One, Two, Three, Four, Five, Six, Seven, Eight];
export default Twilight;
